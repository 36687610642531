import React from "react";
import { Container } from "react-bootstrap";

import Layout from "../components/layout";
import Seo from "../components/seo";

const PrivacyPolicy = ({ location, path }) => {
  return (
    <Layout location={location} path={path}>
      <Seo title="Privacy Policy" />
      <Container className="py-5">
        <div className="mb-5">
          <h1>Privacy Policy</h1>
        </div>
        <p>
          Personal data (usually referred to just as „data“ below) will only be
          processed by us to the extent necessary and for the purpose of
          providing a functional and user-friendly website, including its
          contents, and the services offered there.
        </p>
        <p>
          Per Art. 4 No. 1 of Regulation (EU) 2016/679, i.e. the General Data
          Protection Regulation (hereinafter referred to as the „GDPR“),
          „processing“ refers to any operation or set of operations such as
          collection, recording, organization, structuring, storage, adaptation,
          alteration, retrieval, consultation, use, disclosure by transmission,
          dissemination, or otherwise making available, alignment, or
          combination, restriction, erasure, or destruction performed on
          personal data, whether by automated means or not.
        </p>
        <p>
          The following privacy policy is intended to inform you in particular
          about the type, scope, purpose, duration, and legal basis for the
          processing of such data either under our own control or in conjunction
          with others. We also inform you below about the third-party components
          we use to optimize our website and improve the user experience which
          may result in said third parties also processing data they collect and
          control.
        </p>
        <p>Our privacy policy is structured as follows:</p>
        <p>
          I. Information about us as controllers of your data
          <br />
          II. The rights of users and data subjects
          <br />
          III. Information about the data processing
        </p>
        <h3>I. Information about us as controllers of your data</h3>
        <p>
          The party responsible for this website (the „controller“) for purposes
          of data protection law is:
        </p>
        Heftige Webseiten
        <br />
        Thomas De Cicco
        <br />
        Kampenwandstraße 8<br />
        Pfaffing
        <p>E-Mail: t.heft@heftigewebseiten.de </p>
        <p>The controller’s data protection officer is:</p>
        Thomas De Cicco
        <br />
        Kampenwandstraße 8<br />
        Pfaffing
        <p>E-Mail: t.heft@heftigewebseiten.de </p>
        <h3>II. The rights of users and data subjects</h3>
        <p>
          With regard to the data processing to be described in more detail
          below, users and data subjects have the right
        </p>
        <ul>
          <li>
            to confirmation of whether data concerning them is being processed,
            information about the data being processed, further information
            about the nature of the data processing, and copies of the data (cf.
            also Art. 15 GDPR);
          </li>
          <li>
            to correct or complete incorrect or incomplete data (cf. also Art.
            16 GDPR);
          </li>
          <li>
            to the immediate deletion of data concerning them (cf. also Art. 17
            DSGVO), or, alternatively, if further processing is necessary as
            stipulated in Art. 17 Para. 3 GDPR, to restrict said processing per
            Art. 18 GDPR;
          </li>
          <li>
            to receive copies of the data concerning them and/or provided by
            them and to have the same transmitted to other providers/controllers
            (cf. also Art. 20 GDPR);
          </li>
          <li>
            to file complaints with the supervisory authority if they believe
            that data concerning them is being processed by the controller in
            breach of data protection provisions (see also Art. 77 GDPR).
          </li>
        </ul>
        <p>
          In addition, the controller is obliged to inform all recipients to
          whom it discloses data of any such corrections, deletions, or
          restrictions placed on processing the same per Art. 16, 17 Para. 1, 18
          GDPR. However, this obligation does not apply if such notification is
          impossible or involves a disproportionate effort. Nevertheless, users
          have a right to information about these recipients.
        </p>
        <p>
          <strong>
            Likewise, under Art. 21 GDPR, users and data subjects have the right
            to object to the controller’s future processing of their data
            pursuant to Art. 6 Para. 1 lit. f) GDPR. In particular, an objection
            to data processing for the purpose of direct advertising is
            permissible.
          </strong>
        </p>
        <h3>III. Information about the data processing</h3>
        <p>
          Your data processed when using our website will be deleted or blocked
          as soon as the purpose for its storage ceases to apply, provided the
          deletion of the same is not in breach of any statutory storage
          obligations or unless otherwise stipulated below.
        </p>
        <h4 className="jet-listing-dynamic-field__content">Cookie Manager </h4>
        <p>
          To obtain consent for the use of technically unnecessary cookies on
          the website, the provider uses a cookie manager.
        </p>
        <p>
          When the website is called up, a cookie with the settings information
          is stored on the end device of the user so that the request for
          consent does not have to be made on a subsequent visit.
        </p>
        <p>The cookie is required to obtain legally compliant user consent.</p>
        <p>
          You can prevent cookies from being installed by adjusting the settings
          on your internet browser.
        </p>
        <h4 className="jet-listing-dynamic-field__content">Cookies</h4>
        <h5>a) Session cookies</h5>
        <p>
          We use cookies on our website. Cookies are small text files or other
          storage technologies stored on your computer by your browser. These
          cookies process certain specific information about you, such as your
          browser, location data, or IP address.
        </p>
        <p>
          This processing makes our website more user-friendly, efficient, and
          secure, allowing us, for example, to display our website in different
          languages or to offer a shopping cart function.
        </p>
        <p>
          The legal basis for such processing is Art. 6 Para. 1 lit. b) GDPR,
          insofar as these cookies are used to collect data to initiate or
          process contractual relationships.
        </p>
        <p>
          If the processing does not serve to initiate or process a contract,
          our legitimate interest lies in improving the functionality of our
          website. The legal basis is then Art. 6 Para. 1 lit. f) GDPR.
        </p>
        <p>When you close your browser, these session cookies are deleted.</p>
        <h5>b) Third-party cookies</h5>
        <p>
          If necessary, our website may also use cookies from companies with
          whom we cooperate for the purpose of advertising, analyzing, or
          improving the features of our website.
        </p>
        <p>
          Please refer to the following information for details, in particular
          for the legal basis and purpose of such third-party collection and
          processing of data collected through cookies.
        </p>
        <h5>c) Disabling cookies</h5>
        <p>
          You can refuse the use of cookies by changing the settings on your
          browser. Likewise, you can use the browser to delete cookies that have
          already been stored. However, the steps and measures required vary,
          depending on the browser you use. If you have any questions, please
          use the help function or consult the documentation for your browser or
          contact its maker for support. Browser settings cannot prevent
          so-called flash cookies from being set. Instead, you will need to
          change the setting of your Flash player. The steps and measures
          required for this also depend on the Flash player you are using. If
          you have any questions, please use the help function or consult the
          documentation for your Flash player or contact its maker for support.
        </p>
        <p>
          If you prevent or restrict the installation of cookies, not all of the
          functions on our site may be fully usable.
        </p>
        <h4 className="jet-listing-dynamic-field__content">Contact</h4>
        <p>
          If you contact us via email or the contact form, the data you provide
          will be used for the purpose of processing your request. We must have
          this data in order to process and answer your inquiry; otherwise we
          will not be able to answer it in full or at all.
        </p>
        <p>
          The legal basis for this data processing is Art. 6 Para. 1 lit. b)
          GDPR.
        </p>
        <p>
          Your data will be deleted once we have fully answered your inquiry and
          there is no further legal obligation to store your data, such as if an
          order or contract resulted therefrom.
        </p>
        <h4 className="jet-listing-dynamic-field__content">Server data</h4>
        <p>
          For technical reasons, the following data sent by your internet
          browser to us or to our server provider will be collected, especially
          to ensure a secure and stable website: These server log files record
          the type and version of your browser, operating system, the website
          from which you came (referrer URL), the webpages on our site visited,
          the date and time of your visit, as well as the IP address from which
          you visited our site.
        </p>
        <p>
          The data thus collected will be temporarily stored, but not in
          association with any other of your data.
        </p>
        <p>
          The basis for this storage is Art. 6 Para. 1 lit. f) GDPR. Our
          legitimate interest lies in the improvement, stability, functionality,
          and security of our website.
        </p>
        <p>
          The data will be deleted within no more than seven days, unless
          continued storage is required for evidentiary purposes. In which case,
          all or part of the data will be excluded from deletion until the
          investigation of the relevant incident is finally resolved.
        </p>
        <h4 className="jet-listing-dynamic-field__content">YouTube</h4>
        <p>
          We maintain an online presence on YouTube to present our company and
          our services and to communicate with customers/prospects. YouTube is a
          service of Google Ireland Limited, Gordon House, Barrow Street, Dublin
          4, Ireland, a subsidiary of Google LLC, 1600 Amphitheater Parkway,
          Mountain View, CA 94043 USA.
        </p>
        <p>
          We would like to point out that this might cause user data to be
          processed outside the European Union, particularly in the United
          States. This may increase risks for users that, for example, may make
          subsequent access to the user data more difficult. We also do not have
          access to this user data. Access is only available to YouTube.
        </p>
        <p>The YouTube privacy policy can be found here:</p>
        <p>
          <a
            href="https://policies.google.com/privacy"
            target="_blank"
            rel="noreferrer nofollow"
          >
            https://policies.google.com/privacy
          </a>
        </p>
        <h4 className="jet-listing-dynamic-field__content">
          General introduction
        </h4>
        <h4 className="jet-listing-dynamic-field__content">YouTube</h4>
        <p>
          Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irland,
          a subsidiary of Google LLC, 1600 Amphitheatre Parkway, Mountain View,
          CA 94043 USA
        </p>
        <p>
          Privacy Policy:{" "}
          <a
            href="https://policies.google.com/privacy"
            target="_blank"
            rel="noreferrer nofollow"
          >
            https://policies.google.com/privacy
          </a>
        </p>
        <h4 className="jet-listing-dynamic-field__content">BootstrapCDN</h4>
        <p>
          We use the service BootstrapCDN, a content delivery network (“CDN”),
          on our website to optimise the speed of retrieval, the design and
          presentation of the contents of our website on different
          devices.&nbsp; This is a service of StackPath, LLC, 2021 McKinney
          Ave.&nbsp; Suite 1100, Dallas, TX 75201, USA, hereinafter simply
          referred to as „BootstrapCDN“.
        </p>
        <p>
          In order to be able to deliver the content of our website quickly, the
          service uses so-called JavaScript libraries.&nbsp; Corresponding files
          are loaded from a BootstrapCDN server that records your IP
          address.&nbsp;
        </p>
        <p>At</p>
        <p>
          <a
            href="https://www.stackpath.com/legal/master-service-agreement/#privacy"
            rel="nofollow noreferrer"
            target="_blank"
          >
            https://www.stackpath.com/legal/master-service-agreement/#privacy
          </a>
          &nbsp;&nbsp;
        </p>
        <p>Prospectone Sp.z.o.o. offers further data protection information.</p>
        <p>
          The legal basis is Art.&nbsp; 6 para. 1 lit. f) GDPR. Our legitimate
          interest lies in speeding up loading times and protecting our website,
          as well as in analysing and optimising our website.
        </p>
        <p>
          To totally prevent the execution of BootstrapCDN’s JavaScript code,
          you can install a so-called JavaScript blocker, such as noscript.net
          or ghostery.com.&nbsp; If you were to prevent or restrict the
          execution of the Java Script code, this can mean that for technical
          reasons not all the content and functions of our website may be
          available.
        </p>
        <h4 className="jet-listing-dynamic-field__content">Cookiebot</h4>
        <p>
          We use Cookiebot to display cookie banners. Cookiebot is a product of
          Cybot A/S, Havnegade 39, 1058 Copenhagen, Denmark, hereinafter
          „Cybot.“{" "}
        </p>
        <p>
          Cookiebot informs you about the use of cookies on the website and
          allows you to make a decision about their use.{" "}
        </p>
        <p>
          If you agree to the use of cookies, the following data will be
          automatically recorded by Cybot:
        </p>
        <p>
          • your anonymized IP address;
          <br />• the date and time of your consent;
          <br />• the user agent of your browser;
          <br />• the provider’s URL;
          <br />• an anonymous, random, and encrypted key.
          <br />• your cookie status which serves as proof of consent.
        </p>
        <p>
          The encrypted key and cookie status are stored by means of a cookie on
          your device to enable the corresponding cookie status to be restored
          when you return to our site. This cookie automatically deletes after
          12 months.&nbsp;{" "}
        </p>
        <p>
          The legal basis for this processing of data is{" "}
          <a
            title="Art. 6 GDPR: Lawfulness of processing"
            href="http://dejure.org/gesetze/DSGVO/6.html"
            target="_blank"
            rel="noreferrer nofollow"
          >
            Art. 6 para. 1 lit. f GDPR
          </a>
          . Our legitimate interest is in the user-friendliness of the website
          and the fulfillment of the statutory requirements of GDPR.
        </p>
        <p>
          You can prevent cookies from being installed and/or delete this cookie
          by adjusting the settings on your internet browser.
        </p>
        <p>Go to this link to read the Cybot privacy policy:</p>
        <p>
          <a
            href="https://www.cookiebot.com/de/privacy-policy/"
            target="_blank"
            rel="noreferrer nofollow"
          >
            https://www.cookiebot.com/de/privacy-policy/
          </a>
        </p>
        <h4 className="jet-listing-dynamic-field__content">FontAwesome</h4>
        <p>
          In order to be able to display fonts and visual elements of our
          website, we use FontAwesome’s external fonts.&nbsp; FontAwesome is a
          service of
        </p>
        <p>
          Fonticons Inc., 6 Porter Road, Apartment 3R, Cambridge, MA 02140, USA,
          hereinafter referred to as „FontAwesome“.
        </p>
        <p>
          When you access our website, a connection is established to the
          FontAwesome server in the USA in order to enable and update the
          display of fonts and visual elements.
        </p>
        <p>
          The legal basis is Art.&nbsp; 6 para. 1 lit. f) GDPR. Our legitimate
          interest lies in the optimisation and economic operation of our
          website.
        </p>
        <p>
          By connecting to the FontAwesome server when you access our website,
          FontAwesome can determine from which website your request was sent and
          to which IP address the font should be sent.
        </p>
        <p>At</p>
        <p>
          <a
            href="https://fontawesome.com/privacy"
            target="_blank"
            rel="noreferrer nofollow"
          >
            https://fontawesome.com/privacy
          </a>
        </p>
        <p>
          FontAwesome offers further information, in particular on the options
          for preventing data use.
        </p>
        <h4 className="jet-listing-dynamic-field__content">Google Fonts</h4>
        <p>
          Our website uses Google Fonts to display external fonts. This is a
          service provided by Google Ireland Limited, Gordon House, Barrow
          Street, Dublin 4, Irland (hereinafter: Google).
        </p>
        <p>
          To enable the display of certain fonts on our website, a connection to
          the Google server in the USA is established whenever our website is
          accessed.
        </p>
        <p>
          The legal basis is Art. 6 Para. 1 lit. f) GDPR. Our legitimate
          interest lies in the optimization and economic operation of our site.
        </p>
        <p>
          When you access our site, a connection to Google is established from
          which Google can identify the site from which your request has been
          sent and to which IP address the fonts are being transmitted for
          display.
        </p>
        <p>Google offers detailed information at</p>
        <p>
          <a
            href="https://adssettings.google.com/authenticated"
            target="_blank"
            rel="noreferrer nofollow"
          >
            https://adssettings.google.com/authenticated
          </a>
        </p>
        <p>
          <a
            href="https://policies.google.com/privacy"
            target="_blank"
            rel="noreferrer nofollow"
          >
            https://policies.google.com/privacy
          </a>
        </p>
        <p>in particular on options for preventing the use of data.</p>
        <h4 className="jet-listing-dynamic-field__content">Vimeo</h4>
        <p>
          We use Vimeo to display videos on our website. This is a service of
          Vimeo, LLC, 555 West 18th Street, New York, New York 10011, USA,
          hereinafter referred to as „Vimeo.“
        </p>
        <p>
          In case you have granted your consent to this processing the legal
          basis is Article 6 para. 1 lit. a GDPR. The legal basis can also be
          Article 6 para. 1 lit. f GDPR. Our legitimate interest lies in
          improving the quality of our website.
        </p>
        <p>
          When you access a page on our website that has Vimeo embedded in it, a
          connection to Vimeo’s servers in the USA is established. For technical
          reasons, it is necessary for Vimeo to process your IP address. In
          addition, the date and time of your visit to our website will also be
          recorded.
        </p>
        <p>
          If you are logged into Vimeo while visiting one of our webpages
          containing a Vimeo video, Vimeo will associate information about your
          interaction with our site to your Vimeo account. If you wish to
          prevent this, you must either log out of Vimeo before visiting our
          website or configure your Vimeo user account accordingly.
        </p>
        <p>
          For the purpose of functionality and usage analysis, Vimeo uses Google
          Analytics. Google Analytics stores cookies on your terminal device via
          your browser and transmits information about the use of those websites
          in which a Vimeo video is embedded to Google. It is possible that
          Google will process this information in the USA.
        </p>
        <p>
          If you do not agree to this processing, you have the option of
          preventing the installation of cookies by making the appropriate
          settings in your browser. Further details can be found in the section
          about cookies above.
        </p>
        <p>
          The legal basis for collecting and processing this information is Art.
          6 Para. 1 lit. f) GDPR. Our legitimate interest lies in improving the
          quality of our website and in Vimeo’s legitimate interest in
          statistically analyzing user behavior for optimization and marketing
          purposes.
        </p>
        <p>
          Vimeo offers further information about its data collection and
          processing as well your rights and your options for protecting your
          privacy at this link:
        </p>
        <p>
          <a
            href="http://vimeo.com/privacy"
            target="_blank"
            rel="noreferrer nofollow"
          >
            http://vimeo.com/privacy
          </a>
          .
        </p>
        <h4 className="jet-listing-dynamic-field__content">YouTube</h4>
        <p>
          We use YouTube on our website. This is a video portal operated by
          Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irland,
          hereinafter referred to as „YouTube“.
        </p>
        <p>
          We use YouTube in its advanced privacy mode to show you videos. In
          case you have granted your consent to this processing the legal basis
          is Article 6 para. 1 lit. a GDPR. The legal basis can also be Article
          6 para. 1 lit. f GDPR. Our legitimate interest lies in improving the
          quality of our website. According to YouTube, the advanced privacy
          mode means that the data specified below will only be transmitted to
          the YouTube server if you actually start a video.
        </p>
        <p>
          Without this mode, a connection to the YouTube server in the USA will
          be established as soon as you access any of our webpages on which a
          YouTube video is embedded.
        </p>
        <p>
          This connection is required in order to be able to display the
          respective video on our website within your browser. YouTube will
          record and process at a minimum your IP address, the date and time the
          video was displayed, as well as the website you visited. In addition,
          a connection to the DoubleClick advertising network of Google is
          established.
        </p>
        <p>
          If you are logged in to YouTube when you access our site, YouTube will
          assign the connection information to your YouTube account. To prevent
          this, you must either log out of YouTube before visiting our site or
          make the appropriate settings in your YouTube account.
        </p>
        <p>
          For the purpose of functionality and analysis of usage behavior,
          YouTube permanently stores cookies on your device via your browser. If
          you do not agree to this processing, you have the option of preventing
          the installation of cookies by making the appropriate settings in your
          browser. Further details can be found in the section about cookies
          above.
        </p>
        <p>
          Further information about the collection and use of data as well as
          your rights and protection options in Google’s privacy policy found at
        </p>
        <p>
          <a
            href="https://policies.google.com/privacy"
            target="_blank"
            rel="noreferrer nofollow"
          >
            https://policies.google.com/privacy
          </a>
        </p>
        <p>
          <a
            href="https://www.generator-datenschutzerklärung.de"
            target="_blank"
            rel="noreferrer"
          >
            Model Data Protection Statement
          </a>{" "}
          for{" "}
          <a
            href="https://www.ratgeberrecht.eu/"
            target="_blank"
            rel="noreferrer"
          >
            Anwaltskanzlei Weiß &amp; Partner
          </a>
        </p>
      </Container>
    </Layout>
  );
};

export default PrivacyPolicy;
